/* @import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

/* reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

.container {
  background: #fff;
  width: 70%;
  padding: 5%;
  margin: auto;
  margin-top: 25px;
  border-radius: 10px;
}

.container__title {
  font-size: 20px;
  margin: 10px 0px;
  font-weight: bold;
}

.favorites__title {
  font-size: 20px;
  margin: 25px 0px 10px;
}

.favorites__item {
  background-color:rgb(236, 236, 236);
  padding: 5px;
  margin: 5px 0px;
  border: 1px;
  border-radius: 10px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  padding: 2px;
} 

.button:hover {
  background-color: rgb(221, 220, 220);
} 

.image {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 480px) and (max-width: 767px){
  body {
    font-size: 23px;
  }

  .container__title {
    font-size: 30px;
  }

  .favorites__title {
    font-size: 28px;
  }

}

@media screen and (min-width: 768px) and (max-width: 1023px){
  body {
    font-size: 28px
  }

  .container__title {
    font-size: 40px;
  }

  .favorites__title {
    font-size: 33px;
  }

  .favorites__item {
    margin: 8px 0px;
  }
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 33px
  }

  .container__title {
    font-size: 55px;
  }

  .favorites__title {
    font-size: 40px;
  }

  .favorites__item {
    margin: 10px 0px;
  }
  
} 